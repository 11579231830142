.weatherForecastContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    > h4 {
      margin: 0;
    }
  }

  .inputToggleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    > h4 {
      margin: 0 0.5rem;
    }
  }
}
